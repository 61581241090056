import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemState,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import PracticeAreasSidebar from "../components/Repeating/PracticeAreasSidebar";
import Attorneys from "../components/Repeating/Attorneys";
import WhyUs from "../components/Repeating/WhyUs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const accordionContent = [
    {
      title: "Administrator",
      content: (
        <>
          <p>
            An administrator is a personal representative appointed by the court
            to administer an estate when someone dies without a will or if the
            executor named in the decedent’s will cannot carry out their duties.
          </p>
        </>
      ),
    },
    {
      title: "Assets",
      content: (
        <>
          <p>
            Assets are a decedent’s property, including bank accounts, real
            estate, stocks, bonds, cars, furniture, jewelry, and other personal
            belongings.
          </p>
        </>
      ),
    },
    {
      title: "Beneficiary",
      content: (
        <>
          <p>
            A beneficiary is an individual or entity to whom a donative transfer
            of property is made. Beneficiaries are typically named in a will
            and/or trust.
          </p>
        </>
      ),
    },
    {
      title: "Bequest",
      content: (
        <>
          <p>
            The term 'bequest' refers to a gift of property as instructed in a
            will. Other terms that refer to the transfer of property by a will
            include legacy and devise.
          </p>
        </>
      ),
    },
    {
      title: "Bond",
      content: (
        <>
          <p>
            Bonds, issued by a third-party company, protect beneficiaries,
            heirs, and/or creditors from a personal representative who fails to
            perform their duties properly.
          </p>
        </>
      ),
    },
    {
      title: "Creditor",
      content: (
        <>
          <p>
            A creditor is an individual or entity who may have a legal claim
            against the estate’s property.
          </p>
        </>
      ),
    },
    {
      title: "Decedent",
      content: (
        <>
          <p>A decedent is a person who has passed away.</p>
        </>
      ),
    },
    {
      title: "Estate",
      content: (
        <>
          <p>
            The term ‘estate’ refers to all property owned by the decedent at
            their death, including real property and personal property. All
            estate assets are collected and managed by the personal
            representative during probate.
          </p>
        </>
      ),
    },
    {
      title: "Executor",
      content: (
        <>
          <p>
            An executor is a personal representative appointed by the court to
            administer an estate when someone dies with a will. The executor is
            usually named in the will.
          </p>
        </>
      ),
    },
    {
      title: "Heir",
      content: (
        <>
          <p>
            An heir is an individual or entity legally entitled to the assets of
            someone who has passed away.
          </p>
        </>
      ),
    },
    {
      title: "Issue",
      content: (
        <>
          <p>
            The term 'issue' refers to the lineal descendants of the decedent,
            such as their children or grandchildren.
          </p>
        </>
      ),
    },
    {
      title: "Letters",
      content: (
        <>
          <p>
            “Letters” refer to a Judicial Council form (DE-150) that the Court
            issues after an Executor or Administrator is appointed. Letters are
            used by the Personal Representative to obtain possession of estate
            assets and to open estate bank accounts. Letters also indicate that
            the personal representative is authorized to act on behalf of the
            estate. Filing of a bond is often required before the issuance of
            Letters.
          </p>
        </>
      ),
    },
    {
      title: "Personal Representative",
      content: (
        <>
          <p>
            A personal representative is a general term that refers to an
            executor or an administrator appointed by the court.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Probate Attorney in San Diego | Brierton, Jones & Jones"
        description="Need a top probate attorney in San Diego? Brierton, Jones & Jones, LLP will connect you with an experienced attorney for your needs. Call us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />
      <section className="bg-gray-50 pt-12 pb-6 md:pb-16 md:pt-10">
        <div className="relative">
          <div className="container">
            <div className="grid items-center gap-y-14 md:min-h-[506px] md:grid-cols-2">
              <div className="md:pr-6 lg:pr-12">
                <div className="mb-6 text-sm font-bold uppercase tracking-wide text-gray-300">
                  GET HELP WITH THE PROCESS
                </div>
                <h1>San Diego Probate Attorney</h1>
                <p>
                  Probate can be quite challenging—the rules are complex, and
                  emotions often run high. Don’t go it alone. Our experienced
                  probate attorneys will move it along efficiently, resolve
                  issues fairly, and work to avoid conflicts.
                </p>
                <ButtonSolid
                  modal="modal-contact"
                  text="Schedule a Consultation"
                />
              </div>

              <div className="-mr-4 md:absolute md:right-0 md:top-0 md:mr-0 md:h-full md:w-[50vw] md:pl-6 lg:pl-12">
                <StaticImage
                  src="../images/4.0 Probate/1.0 Hero desktop.jpg"
                  loading="eager"
                  className="h-full rounded-l-[90px]"
                  imgClassName="object-left"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 pt-14 md:mb-32 md:pt-20">
        <div className="container">
          <div className="flex justify-between md:space-x-14 lg:space-x-28">
            <div>
              <div className="mb-12 md:mb-18">
                <h2>What You Need To Know About Probate</h2>
                <p>
                  Probate is a judicial process where a court reviews the assets
                  of a deceased person, determines who the inheritors are, and
                  orders how those assets will be distributed. It can take up to
                  a year to complete.
                </p>

                <h3>Is Probate Required?</h3>
                <p>
                  Each state has its own probate requirements. Here in
                  California, probate is generally required if the estate’s
                  value exceeds $166,250.
                </p>

                <h3>How Does It Work if You Have a Will?</h3>
                <p>
                  The executor you’ve named to handle your estate is responsible
                  for initiating probate with the court upon your death. First,
                  the court will authenticate your will, and then your chosen
                  executor is granted legal power to act on your behalf to
                  complete obligations and fulfill your wishes.
                </p>

                <h3>How Does It Work if You Don’t Have a Will?</h3>
                <p>
                  The court appoints an administrator to handle the probate
                  process and distribute your assets according to state law. The
                  administrator will attempt to locate legal heirs so the
                  property can be divided amongst them. In most cases, the heirs
                  are your surviving spouse and your children.
                </p>
              </div>

              <div>
                <h3>Understanding the Terminology</h3>
                <p>
                If you find yourself involved in the probate process, here are some of the common terms to familiarize yourself with:
                </p>
                <Accordion
                  allowZeroExpanded={true}
                  className="flex flex-col space-y-5 border-t border-gray-100 pt-5"
                >
                  {accordionContent.map((item, i) => (
                    <AccordionItem
                      uuid={i}
                      className="border-b border-gray-100 pb-5"
                    >
                      <AccordionItemButton className="flex items-center justify-between focus:outline-none">
                        <AccordionItemState>
                          {(state) => {
                            return (
                              <>
                                <h3 className="mb-0 font-display text-xl font-medium text-typography-heading no-underline">
                                  {item.title}
                                </h3>
                                <i
                                  className={`far ml-2 text-xl text-seafoam ${
                                    state.expanded
                                      ? "fa-minus"
                                      : "fa-chevron-down"
                                  }`}
                                ></i>
                              </>
                            );
                          }}
                        </AccordionItemState>
                      </AccordionItemButton>

                      <AccordionItemPanel className="pt-5">
                        {item.content}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
              </div>
            </div>
            <div className="hidden md:block">
              <PracticeAreasSidebar activeLink={1} />
            </div>
          </div>
        </div>
      </section>

      <Attorneys />
      <WhyUs />
      <Testimonials />
      <CallToAction text="Whether you’re the representative of an estate or a potential beneficiary, you’ll want experienced legal counsel by your side. The San Diego probate attorneys at Brierton, Jones & Jones, LLP are here for you." />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Facebook/02 Probate.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graphs/Twitter/02 Probate.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
